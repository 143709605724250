<template>
  <div>
    <!--    搜索框-->
    <div class="container">
      <h2 class="search-font">
        查询USDT交易明细并快速统计收支
      </h2>
      <div class="search">
        <a-input-search class="input-search" placeholder="请输入要查询的地址" search-button @search="search">
          <template #button-icon>
            <icon-search/>
          </template>
          <template #button-default>
            搜索
          </template>
        </a-input-search>
      </div>
    </div>
    <!-- 导航-->
    <div class="container">
      <div class="nav-item">
        <div class="nav-font-price">欧易实时购买价：1USDT={{ nowPrice }} RMB</div>
        <div class="nav-font-info">（前10名商家的平均价格）</div>
        <!-- 四个按钮-->
        <div class="row">
          <div class="col-md-3" @click="linkCus">
            <div class="cover">
              <div>
                <a-avatar :size="56" style="background-color: inherit !important;">
                  <img alt="avatar" :src="require('@/assets/tel.png')"/>
                </a-avatar>
              </div>
              <div class="item-font-one">USDT和TRX</div>
              <div class="item-font-one">转入转出消息提醒（官方）</div>
            </div>
          </div>
          <div class="col-md-3" @click="toTRX">
            <div class="cover">
              <div>
                <a-avatar :size="56" style="background-color: inherit !important;">
                  <img alt="avatar" :src="require('@/assets/TRX1.png')"/>
                </a-avatar>
              </div>
              <div class="item-font-two">自动兑换</div>
              <div class="item-font-one">USDT -> TRX</div>
              <div class="item-font-one">站长直营</div>
            </div>
          </div>
          <div class="col-md-3" @click="toDanbao">
            <div class="cover">
              <div>
                <a-avatar :size="56" style="background-color: inherit !important;">
                  <img alt="avatar" :src="require('@/assets/danbao.png')"/>
                </a-avatar>
              </div>
              <div class="item-font-two">51担保网</div>
              <div class="item-font-one">全球首家USDT 担保交易平台</div>
              <div class="item-font-one">站长直营</div>
            </div>
          </div>
          <!--<div class="col-md-3">-->
          <!--  <div class="cover" style="">-->
          <!--    <div>-->
          <!--      <a-avatar :size="56" style="background-color: inherit !important;">-->
          <!--        <img alt="avatar" :src="require('@/assets/cloud.png')"/>-->
          <!--      </a-avatar>-->
          <!--    </div>-->
          <!--    <div class="item-font-two">阿里云/腾讯云/亚马逊/自动充值</div>-->
          <!--    <div class="item-font-one">USDT充值自动笔笔送20%</div>-->
          <!--    <div class="item-font-one">不需要实名认证</div>-->
          <!--    <div class="item-font-one">不放心安全问题，可以发邀请链接给你自己注册</div>-->
          <!--  </div>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Message} from '@arco-design/web-vue';
import request from "@/utils/request";

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      nowPrice: '',
      timer: null,
    }
  },
  created() {
    this.queryUsdtPrice()
  },
  beforeDestroy() {
    // 在组件销毁时清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    search(e) {
      if (!e.startsWith('T') || e.length !== 34) {
        return Message.error('输入正确的地址')
      }
      this.$router.push({path: '/order', query: {address: e}})
    },
    linkCus() {
      window.open('https://t.me/chau51_bot', '_blank')
    },
    toDanbao() {
      window.open('https://www.51danbao.com', '_blank')
    },
    toTRX() {
      this.$router.push('/ToTrx')
    },
    queryUsdtPrice() {
      try {
        let url = 'https://api.admin.51danbao.com/api/publicly/validate/code/okexUsdt';
        request.get(url).then(res => {
          this.nowPrice = res.data
        })
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.cover {
  background-color: #FFFFFF;
  height: 313px;
  box-shadow: 0px 0px 9px #ddd;
  color: #1B5C93;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .item-font-one {
    font-size: 17px;
    margin-top: 15px;
    font-weight: 800;
  }

  .item-font-two {
    font-size: 21px;
    margin-top: 15px;
    font-weight: 800;
  }

  .item-font-three {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
  }
}

.cover:hover {
  color: #CCDAE7 !important;
}

.cover:hover:after {
  color: #1B5C93 !important;
}

.container {
  max-width: 1520px;
  min-width: 320px !important;

  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

  .nav-item {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f5f9fc !important;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;

      flex-wrap: wrap;
      margin-top: 52px;
      margin-bottom: 60px;
    }

    .col-md-3 {
      width: 460px;
      padding-right: 10px;
      margin-bottom: 50px;
    }

    .nav-item-cus {
      margin-top: 50px;
      margin-bottom: 30px;

      .grid-demo .arco-col {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 98px;
        line-height: 48px;
        text-align: center;
        background-color: #FFFFFF !important;
      }
    }

    .nav-font-price {
      margin-top: 52px;
      font-size: 20px;
      padding-top: 40px;
      font-weight: 600;
      color: #5c768d !important
    }

    .nav-font-info {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 600;
      color: #9e9e9e !important
    }
  }

  .search {
    min-width: 320px !important;
    width: 70% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 20px;

    .input-search {
      width: 100%;
      height: 54px !important;

      .arco-btn {
        height: 52px !important;
        width: 106px !important;
      }
    }
  }

  .search-font {
    min-width: 320px !important;
    color: #5c768d !important;
    margin-top: 150px;
    font-size: 24px;
  }

  .home-header {
    margin-top: 30px;
    height: 65px;
    min-width: 1200px;

    display: flex;
    justify-content: space-between;

    .main-font {
      font-size: 32px;
    }

    .main-font:hover {
      color: #CCDAE7 !important;
    }

    .main-font:hover:after {
      color: inherit !important;
    }

    .main-url {
      font-size: 22px;
      cursor: pointer;
    }

    .main-url:hover {
      color: #CCDAE7 !important;
    }

    .main-url:hover:after {
      color: inherit !important;
    }

    .main-url-betch {
      font-size: 15px !important;
      margin-left: 20px !important;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      padding-bottom: 10px;
      cursor: pointer;
    }

    .main-url-betch:hover {
      color: #CCDAE7 !important;
    }

    .main-url-betch:hover:after {
      color: inherit !important;
    }

    .main-page-font {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;

      color: #1c5c93;
      font-weight: 700;
    }

    .main-page-font:hover {
      color: #CCDAE7 !important;
    }

    .main-page-font:hover:after {
      color: #1c5c93 !important;
    }

  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  flex: 0;
}

.content {
  flex: 1;
}

.footer {
  flex: 1;
  width: 100%;
  min-height: 212px;
  background-color: #587187;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 60px;

  .footer-email {
    margin-top: 80px;
    font-size: 24px !important;
    color: #FFFFFF;
    cursor: pointer;
  }

  .footer-email:hover {
    color: #CCDAE7 !important;
  }

  .footer-email:hover:after {
    color: #FFFFFF !important;
  }

  .footer-telegram {
    margin-top: 10px;
    font-size: 21px !important;
    color: #FFFFFF;
    cursor: pointer;
  }

  .footer-telegram:hover {
    color: #CCDAE7 !important;
  }

  .footer-telegram:hover:after {
    color: #FFFFFF !important;
  }
}
</style>
